(function () {

    'use strict';

    const {BehaviorSubject} = require("rxjs");


    class PatientByAgeCtrl {
        constructor(genericStatesService, configService, authService, system, $translate, $scope, $state, $stateParams, moment) {
            this.genericStatesService = genericStatesService;
            this.configService = configService;
            this.isRoot = authService.isRootProfile();
            this.system = system;
            this.translate = $translate;
            this.scope = $scope;
            this.$state = $state;
            this.moment = moment;
            this.dateFormat = system['date_format'].js;

            this.currentState = genericStatesService.getCurrentState("patients_by_age");

            this.isChart = false;

            this.ageRanges = genericStatesService.getSubcategory("age_ranges");
            this.range = _.get(this.ageRanges, '0');
            this.chartConfig = {
                type: "pie",
                title: this.translate.instant(this.currentState.label),
                oneLevel: true,
                showOneMeasureSelection: false,
                showWarning: false,
                activeMeasure: {
                    uniqueName: "age_range_count",
                    aggregation: "min"
                }
            }
            this.query = {
                start: $stateParams.start || null,
                end: $stateParams.end || null
            };
            this.headers = {
                _id: {
                    type: "number",
                    caption: this.translate.instant('unique_id')
                },
                birth_date: {
                    type: "date",
                    caption: this.translate.instant('birth_date')
                },
                fullname: {
                    type: "string",
                    caption: this.translate.instant('dcm_patient_full_name')
                },
                age: {
                    type: "number",
                    caption: this.translate.instant('state_patients_age')
                },
                age_range: {
                    type: "number",
                    caption: this.translate.instant('states_ages_range_int')
                },
                age_range_string: {
                    type: "string",
                    caption: this.translate.instant('states_ages_range')
                }
            };


            this.$dataSource = new BehaviorSubject({
                slice: {},
                data: [],
                headers: {},
                onlyData: false
            });
        }

        static get $inject() {
            return ["genericStatesService", "configService", "authService", "system", "$translate", "$scope", "$state", "$stateParams", "moment"];
        }

        $onInit() {
            this.maxDate = this.moment().toDate();
            this.genericStatesService.load()
                .then(() => {
                    const config = this.genericStatesService.$config.getValue();

                    if (!_.isNil(config)) {
                        if (this.isRoot) {
                            this.ageRanges = this.ageRanges.map(item => {
                                item.deactivated = _.get(config, item.name, false);
                                return item;
                            });
                        } else {
                            this.ageRanges = _.reject(this.ageRanges, item => _.get(config, item.name, false));
                            this.range = _.get(this.ageRanges, '0');
                        }

                        this.getData();
                    }
                });
        }

        getSlice() {
            return {
                "expands": {
                    "expandAll": true,
                    "rows": [
                        {"tuple": []}
                    ]
                },
                "drills": {
                    "drillAll": false
                },
                "sorting": {
                    "row": {
                        "type": "asc",
                        "tuple": ["age_range"]
                    }
                },
                "rows": [
                    {
                        "uniqueName": "age_range_string",
                    },
                    {
                        "uniqueName": "fullname",
                    }
                ],
                "columns": [
                    {
                        "uniqueName": "[Measures]"
                    }
                ],
                "measures": [
                    {
                        "uniqueName": "age_range_count",
                        "caption": this.translate.instant("states_total"),
                        "individual": true,
                        "format": "integer",
                        "formula": "count(\"age_range\")",
                    }
                ],
            };
        }

        getData(onlyData = false) {
            return this.promise = this.genericStatesService.getPatientsByAge(_.assign(this.query, {
                range: this.range.type
            })).then(data => {
                if (onlyData) {
                    this.$dataSource.next({
                        onlyData: true,
                        data: data
                    });
                } else {
                    this.$dataSource.next({
                        slice: this.getSlice(),
                        data: data,
                        headers: this.headers
                    });
                }
            });
        }

        handleFileName() {
            return `${this.translate.instant('states_patients_by_age')}`;
        }

        handleAgeBase() {
            this.scope.$applyAsync(() => this.getData(true));
        }

        onComplete(instance) {
            instance.collapseData("age_range_string")
        }

        queryChanged() {
            const start = moment(this.query.start, this.dateFormat);
            const end = moment(this.query.end, this.dateFormat);

            if (start.isAfter(end)) this.query.end = start.endOf('month').format(this.dateFormat);

            this.$state.transitionTo(this.$state.current.name, this.query, {
                notify: false
            }).then(() => this.getData(true));
        }
    }

    module.exports = PatientByAgeCtrl;
})();
