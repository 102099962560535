(function () {

    'use strict';

    class FormDetailCtrl {
        constructor($transition$, $state, $stateParams, $element, system, formsService) {
            this.state = $state;
            this.element = $element;
            this.formsService = formsService;
            this.dateFormat = system['date_format'].js;
            this.currentPatient = _.get($transition$.params('to'), 'pId');
            this.currentForm = _.get($transition$.params('to'), 'form');
            this.currentTemplate = _.get($transition$.params('to'), 'template');
            this.prePrint = this.prePrint.bind(this);
        }

        static get $inject() {
            return ["$transition$", "$state", "$stateParams", "$element", "system", "formsService"];
        }

        $onInit() {
            this.formsService.editFormSubject.next(this.currentForm);
            if (this.currentForm) {
                this.formsService.getForm(this.currentForm).then(data => {
                    this.formTemplate = data;
                });
            } else {
                this.formTemplate = {
                    template: null,
                    date: moment().format(this.dateFormat),
                    patient: {id: this.currentPatient}
                };
                this.formsService.getFormsTemplates(this.currentTemplate).then(data => {
                    this.formTemplate.template = _.cloneDeep(data)
                })

            }
        }

        $onDestroy() {
        }

        saveForm() {
            this.formsService.saveForm(this.formTemplate).then(data => {
                this.formTemplate = data;
                this.formsService.newFormSubject.next(data);
                this.state.go("app.visit.forms.detail", {form: data.id}, {inherit: true}).then(_.noop);
            });
        }

        deleteItem() {
            this.formsService.deleteForm(this.formTemplate.id).then(_.noop);
        }

        prePrint() {
            return this.formsService.saveForm(this.formTemplate);
        }

        checkChoice(f, o) {
            return _.includes(f.value, o);
        }

        addChoice(f, o, idx) {
            if (_.includes(f.value, o)) {
                f.value.splice(idx, 1)
            } else {
                f.value = f.value || [];
                f.value.push(o);
            }
        }
    }

    module.exports = FormDetailCtrl;

})();
