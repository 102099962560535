/**
 * Created by amine on 03/05/2018.
 */
(function () {
    'use strict';

    module.exports = ExamFormCtrl;

    ExamFormCtrl.$inject = ["$scope", "examService", "$state", "$transition$", "visitService", "system", "$q", "observationService"];

    function ExamFormCtrl($scope, examService, $state, $transition$, visitService, system, $q, observationService) {
        let vm = this;
        let dateFormat = system['date_format'].js
        let timeFormat = system['time_format'].js
        let currentVisit;
        let contentSubscription;

        vm.$onInit = init;
        vm.$onDestroy = onDestroy;
        vm.saveExam = saveExam;
        vm.cancel = goBack;
        vm.changeDuration = changeDuration;
        vm.changeEnd = changeEnd;
        vm.removeExam = examService.removeExam;
        vm.addAsModel = examService.addExamModel;

        vm.returnToObservation = id => observationService.returnTo(id);

        function init() {
            vm.observationContext = false;
            vm.template = _.get($transition$.params('to'), 'template', null);
            vm.currentPatient = _.get($transition$.params('to'), 'pId', null);
            currentVisit = _.get($transition$.params('to'), 'visitId', null);

            vm.visitContext = !_.isNil(vm.currentPatient);
            vm.examId = _.get($transition$.params('to'), 'examId', null);

            vm.editorName = _.uniqueId('exam-editor-');
            vm.editorConfigKey = "exam_editor";
            vm.prePrint = examService.prePrint(saveExam, vm.editorName);

            vm.editorOpts = {
                patient: vm.currentPatient
            }

            examService.sideNaveSubject.next(true);
            examService.selectedTemplate.next(vm.template);
            examService.formSubject.next(true);

            contentSubscription = examService.contentSubject.subscribe(addContent);

            if (_.isNil(vm.examId)) handleNew();
            else examService.getExam(vm.examId).then(examSuccess, error);


            function handleNew() {
                let promises = [
                    visitService.getVisit(currentVisit),
                    examService.getExamTemplate(vm.template)
                ]

                let model = _.get($transition$.params('to'), 'model', null);
                let copy = _.get($transition$.params('to'), 'copy', null);
                if (!!model) {
                    examService.currentModelSubject.next({id: model, type: "use", model: true});
                    promises.push(examService.getExamModel(model));
                }
                if (!!copy) {
                    examService.currentModelSubject.next({id: copy, type: "duplicate", model: false});
                    promises.push(examService.getExam(copy));
                }

                $q.all(promises).then(newSuccess, error);
            }

            function newSuccess(data) {
                vm.exam = {
                    visit: _.pick(data[0], 'id'),
                    template: data[1].id,
                    form_body: _.chain(data[1]).get("form_template", null).cloneDeep().value(),
                    exam_date: moment().format(dateFormat), //_.get(data[0], 'visit_date'),
                    start_time: moment().format(timeFormat),
                    content: _.isNil(data[2]) ? "" : data[2].content
                }
            }

            function examSuccess(data) {
                vm.exam = data;
                examService.selectedTemplate.next(vm.exam.template);

                examService.currentModelSubject.next({id: vm.exam.id, type: "edit", model: false});

                if (vm.exam.template.has_form && !vm.exam.form_body) vm.exam.form_body = _.cloneDeep(vm.exam.template.form_template);
            }

            function addContent(model) {
                vm.exam.content = _.isUndefined(vm.exam.content) ? model.content : vm.exam.content + model.content;
            }

            function error() {
                if (vm.visitContext) listState();
            }

            //observation section
            vm.observationId = _.get($transition$.params('to'), 'observationId');
            if (!_.isNil(vm.observationId)) vm.observationContext = true;
        }

        function saveExam(quit) {
            return examService.handleSaving(vm.exam, vm.currentPatient, currentVisit)
                .then(success);

            function success(data) {
                if (vm.observationContext) {
                    observationService.saveExternalReportEntry(data.id, vm.observationId)
                        .then(observationId => {
                            vm.observationId = observationId;
                            afterSave(data, observationId ? {observationId: observationId} : {})
                        })
                } else {
                    afterSave(data);
                }
            }

            function afterSave(data, supQuery = {}) {
                if (quit) return goBack();

                vm.exam.id = data.id;
                examService.editState(data, data.template, false, supQuery);
            }
        }


        function changeEnd() {
            const start_time = moment(vm.exam.start_time, timeFormat);
            const end_time = moment(vm.exam.end_time, timeFormat);

            if (end_time.isValid()) {
                vm.exam.has_duration = true;
            } else {
                vm.exam.end = null;
                vm.exam.has_duration = false;
            }
        }

        function changeDuration() {
            const start_time = moment(vm.exam.start_time, timeFormat);
            const end_time = moment(vm.exam.end_time, timeFormat);

            if (vm.exam.has_duration && !end_time.isValid()) {
                vm.exam.end_time = moment().format(timeFormat);
            } else {
                vm.exam.end_time = null;
            }
        }

        function goBack() {
            if (vm.observationContext) observationService.returnTo(vm.observationId);
            else listState();
        }

        function listState() {
            setTimeout(() => {
                let globalContext = _.get($transition$.params('to'), 'nv', null);

                if (_.isNil(globalContext)) examService.listState();
                else examService.globalListState();
            }, 300);
        }

        function onDestroy() {
            examService.self.formSubject.next(false);
            contentSubscription.unsubscribe();
        }
    }

})
();
