/**
 * Created by amine on 03/05/2018.
 */
(function () {

    'use strict';

    module.exports = ExamViewCtrl;

    ExamViewCtrl.$inject = ["$scope", "examService", "$state", "$transition$", "$sce"];

    function ExamViewCtrl($scope, examService, $state, $transition$, $sce) {
        var vm = this;

        vm.$onInit = init;
        vm.cancel = listState;
        vm.editExam = examService.editExam;
        vm.duplicateExam = examService.duplicateExam;
        vm.removeExam = examService.removeExam;

        function init() {
            vm.currentPatient = _.get($transition$.params('to'), 'pId', null);
            vm.visitContext = !_.isNil(vm.currentPatient);
            vm.examId = _.get($transition$.params('to'), 'examId', null);

            examService.getExam(vm.examId).then(success, error);

            function success(data) {
                vm.exam = data;
                vm.exam.trustedContent = $sce.trustAsHtml(vm.exam.content);

                examService.selectedTemplate.next(vm.exam.template);
            }

            function error() {
                if (vm.visitContext) examService.listState();
            }
        }

        function listState() {
            var globalContext = _.get($transition$.params('to'), 'nv', null);

            if (globalContext) examService.globalListState()
            else examService.listState()
        }
    }

})();
