/**
 * Created by BETALOS on 14/07/2016.
 */
(function () {

    'use strict';

    const {UPDATE, APPOINTMENT} = require('stand-alone/calendar/utils/consts');

    module.exports = {
        locals: {},
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: EntryDialogCtrl,
        template: require("frontdesk/views/entry-dialog.tpl.html"),
    };

    EntryDialogCtrl.$inject = [
        "$mdDialog", "system", "mnWebSocket", "patientService", "frontDeskService", "$mdToast", "$translate", "$state",
        "configService", "$q"
    ];

    function EntryDialogCtrl(
        $mdDialog, system, mnWebSocket, patientService, frontDeskService, $mdToast, $translate, $state, configService,
        $q
    ) {
        let vm = this;

        let dateFormat = system['date_format'].js;
        let dateTimeFormat = system['date_format'].naive;

        vm.$onInit = init;

        vm.toConsultation = false;
        vm.maxDate = moment().toDate();

        vm.cancel = cancel;
        vm.submit = submit;

        vm.query = query;
        vm.removePatient = removePatient;
        vm.patientCallback = patientCallback;
        vm.handleTitleChange = patientService.handleTitleChange;

        function init() {
            if (_.isNil(vm.entryTmp)) {
                vm.entry = {
                    patient: {
                        contact_info: _.assign({phone_numbers: []}, _.get(configService.defaultValues, "contact_info", null)),
                        profession: _.get(configService.defaultValues, "profession", null),
                        file_date: moment().format(dateFormat)
                    },
                    entry_time: moment().format(dateTimeFormat)
                };
                vm.choose = false;
            } else {
                vm.choose = vm.editMode = true;

                vm.promise = frontDeskService.getFullEntry(vm.entryTmp)
                    .then(data => vm.entry = data);
            }

            if (!_.isNil(vm.patient)) patientCallback(vm.patient);
        }

        function submit(is_toPatient, $event) {
            if (_.isUndefined(vm.entry.patient.id)) return checkExistence();
            else return handleEntry();

            function checkExistence() {
                let deferred = $q.defer();

                patientService.checkExistence(vm.entry.patient).then(success, fail);

                function success() {
                    handleEntry().then(deferred.resolve, deferred.reject);
                }

                function fail(data) {
                    const locals = {'patient-id': data.id};
                    const assign = {clickOutsideToClose: false, escapeToClose: false, multiple: true};

                    frontDeskService.entryResume(locals, $event, assign)
                        .then(usePatient, checkEntryHandle);

                    function usePatient() {
                        patientCallback(data).then(success, deferred.reject);
                    }

                    function checkEntryHandle(use) {
                        if (use) success();
                        else deferred.reject();
                    }
                }

                return deferred.promise;
            }

            function handleEntry() {
                vm.entry.stat = 'WR';

                if (vm.toConsultation) {
                    vm.entry.stat = 'SV';
                    vm.entry.visit_start_time = vm.entry.entry_time;
                }

                vm.promise = frontDeskService.handleEntry(vm.entry)
                    .then(doneCallbacks);

                return vm.promise;

                function doneCallbacks(data) {
                    if (data.appointment) {
                        mnWebSocket.pub("frontdesk.Calendar.notify", {
                            cmd: UPDATE,
                            type: APPOINTMENT,
                            event: data.appointment.id
                        }, false);
                    }

                    if (is_toPatient) {
                        $mdDialog.cancel();
                        $state.go("app.patient-form", {'patient_id': data.patient.id});
                    } else {
                        mnWebSocket.pub("frontdesk.Practice.entry_added", data);
                        $mdDialog.hide(data);
                    }
                }
            }

        }

        function cancel() {
            $mdDialog.cancel();
        }

        function query() {
            const nEqual = {"$ne": true};

            return _.chain(vm.entry.patient).pick(['first_name', 'last_name']).assign({
                is_archived: nEqual,
                is_deleted: nEqual,
                is_draft: nEqual
            }).value();
        }

        function patientCallback(patient) {
            const deferred = $q.defer();

            vm.promise = patientService.getMinimalPatient(patient.id, true)
                .then(doneCallback, error);

            function doneCallback(data) {
                vm.entry.patient = data;
                deferred.resolve(true);
            }

            function error() {
                failCallback();
                deferred.reject();
            }

            return deferred.promise;
        }

        function failCallback() {
            vm.choose = false;

            const simpleToast = $mdToast.simple()
                .textContent($translate.instant('patient_has_entry'))
                .position("bottom right")
                .hideDelay(2000);

            $mdToast.show(simpleToast);
        }

        function removePatient() {
            vm.entry.patient = {};
        }
    }

})();
