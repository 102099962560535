/**
 * Created by Hp on 09/05/2017.
 */


(function () {
    'use strict';
    const angular = require('angular');
    const GenericStatesCtrl = require('./controllers/generic-states/GenericStatesCtrl');
    const ActivitiesCtrl = require('./controllers/activities/ActivitiesCtrl');
    const PatientByAgeCtrl = require('./controllers/patient-by-age/PatientByAgeCtrl');
    const PatientAffectationCtrl = require('./controllers/patient-affectation/PatientAffectationCtrl');
    const AppointmentReportCtrl = require('./controllers/appointment-report/AppointmentReportCtrl');
    const InsuredReportCtrl = require('./controllers/insured-report/InsuredReportCtrl');
    const PatientStatusCtrl = require('./controllers/patient-status/PatientStatusCtrl');
    const PathologiesCtrl = require('./controllers/pathologies/PathologiesCtrl');
    const AgingBalanceCtrl = require('./controllers/aging-balance/AgingBalanceCtrl');
    const RecoveryBalanceCtrl = require('./controllers/recovery-balance/RecoveryBalanceCtrl');
    const PregnancyReport = require('./controllers/pregnancy-report/PregnancyReport');
    const MedicineReport = require('./controllers/medicine-report/MedicineReport');
    const NewPatientsCtrl = require('./controllers/new-patients/NewPatientsCtrl');
    const CheckChecksCtrl = require('./controllers/check-checks/CheckChecksCtrl');
    const DueDatesReportCtrl = require('./controllers/due-dates-report/DueDatesReportCtrl');
    const StatesSetupCtrl = require('./controllers/states-setup/StatesSetupCtrl');

    const genericStatesService = require("./services/genericStatesService");

    const PivotTable = require("./components/pivot-table/PivotTable");

    angular
        .module("medinet")
        .component("mnPivotTable", PivotTable)
        .service("genericStatesService", genericStatesService)

        .controller('GenericStatesCtrl', GenericStatesCtrl)
        .controller('ActivitiesCtrl', ActivitiesCtrl)
        .controller('PatientByAgeCtrl', PatientByAgeCtrl)
        .controller('PatientAffectationCtrl', PatientAffectationCtrl)
        .controller('AppointmentReportCtrl', AppointmentReportCtrl)
        .controller('InsuredReportCtrl', InsuredReportCtrl)
        .controller('PatientStatesCtrl', PatientStatusCtrl)
        .controller('PathologyCtrl', PathologiesCtrl)
        .controller('AgingBalancesCtrl', AgingBalanceCtrl)
        .controller('RecoveryBalanceCtrl', RecoveryBalanceCtrl)
        .controller('PregnancyReport', PregnancyReport)
        .controller('MedicineReport', MedicineReport)
        .controller('NewPatientsCtrl', NewPatientsCtrl)
        .controller('CheckChecksCtrl', CheckChecksCtrl)
        .controller('DueDatesReportCtrl', DueDatesReportCtrl)
        .controller('StatesSetupCtrl', StatesSetupCtrl);
})();