/**
 * Created by amine on 09/05/2018.
 */
(function () {

    'use strict';

    module.exports = ReportsMainCtrl;

    ReportsMainCtrl.$inject = ["$state", "$transitions"];

    function ReportsMainCtrl($state, $transitions) {
        let vm = this;
        let listener = _.noop;

        vm.$onInit = init;
        vm.$onDestroy = onDestroy;

        function init() {
            if ($state.is("app.reports")) $state.go("app.reports.list", {});

            listener = $transitions.onBefore({}, toList);

            function toList(transition) {
                let state = transition.to();

                if (!_.eq(state.name, "app.reports.list") && _.eq(state.name, "app.reports")) {
                    $state.go("app.reports.list", {});
                }

                return true;
            }
        }

        function onDestroy() {
            listener()
        }
    }

})();
