/**
 * Created by amine on 30/04/2018.
 */
(function () {

    'use strict';

    module.exports = ExamContainerCtrl;

    ExamContainerCtrl.$inject = [
        "$scope", "examService", "visitService", "$mdDialog", "$state", "$transitions", "$transition$", "observationService"
    ];

    function ExamContainerCtrl($scope, examService, visitService, $mdDialog, $state, $transitions, $transition$, observationService) {
        let vm = this;
        let currentTemplate = null;
        let listener = _.noop;
        let initModels = [];
        let initExams = [];


        vm.$onInit = init;
        vm.listState = examService.listState;
        vm.useItem = useItem;
        vm.editItem = editItem;
        vm.deleteItem = deleteItem;
        vm.duplicateItem = duplicateItem;
        vm.useModel = useModel;
        vm.addModel = addModel;
        vm.toggleModels = toggleModels;
        vm.editModel = examService.editModelState;
        vm.duplicateModel = examService.duplicateModelState;
        vm.moveModel = examService.moveExamModel;
        vm.deleteModel = examService.deleteExamModel;

        vm.returnToObservation = id => observationService.returnTo(id);

        function init() {
            vm.lock = false;
            vm.observationContext = false;
            vm.showModel = true;

            vm.currentPatient = _.get($transition$.params('to'), 'pId');

            if ($state.is("app.visit.exam")) examService.listState();

            let sideNaveSubscription = examService.sideNaveSubject.subscribe(showList);
            let formSubscription = examService.formSubject.subscribe(formState);
            let selectedTemplateSubscription = examService.selectedTemplate.subscribe(filterExamModels);
            let currentModelSubscription = examService.currentModelSubject.subscribe(currentModelChanged);
            let modelsSubscription = examService.examModelSubject.subscribe(modelsChanged);
            let currentPatientSubscription = visitService.currentPatientSubject.subscribe(handlePatient);

            listener = $transitions.onBefore({}, toList);

            $scope.$on("$destroy", onDestroy);

            examService.getExamModels().then(success);

            function toList(transition) {
                let state = transition.to();

                if (!_.eq(state.name, "app.visit.exam.list-view") && _.eq(state.name, "app.visit.exam")) {
                    examService.listState();
                    return false;
                }

                return true;
            }

            function handlePatient(data) {
                vm.patient = data;
            }

            function showList(lock) {
                vm.lock = _.isNil(lock) ? false : lock;
            }

            function formState(isForm) {
                vm.isForm = isForm;
            }

            function currentModelChanged(status) {
                vm.currentModel = status;
            }

            function modelsChanged(status) {
                if (_.has(status, "added")) {
                    initModels = _.pushOrUpdate(initModels, status.added);
                    filterExamModels(status.added.template);
                }

                if (_.has(status, "deleted")) {
                    let i = _.findIndex(initModels, {id: status.deleted});
                    initModels.splice(i, 1);
                }
            }

            function success(data) {
                initModels = data;
                filterExamModels();
            }

            function onDestroy() {
                listener();

                sideNaveSubscription.unsubscribe();
                currentModelSubscription.unsubscribe();
                formSubscription.unsubscribe();
                selectedTemplateSubscription.unsubscribe();
                modelsSubscription.unsubscribe();
                currentPatientSubscription.unsubscribe();
            }

            //observation section
            vm.observationId = _.get($transition$.params('to'), 'observationId');
            if (!_.isNil(vm.observationId)) vm.observationContext = true;
        }

        function filterExamModels(template) {
            if (_.isNil(template)) template = examService.selectedTemplate.getValue();
            if (template === currentTemplate) return false;

            currentTemplate = template;

            if (template && _.has(template, "id")) {
                vm.models = _.chain(initModels)
                    .filter({template_id: template.id})
                    .sortBy(sortFunc)
                    .value();

                reloadExams(template);
            } else {
                vm.models = _.sortBy(initModels, sortFunc);
                initExams = [];
            }

            if (!vm.list || (vm.list && vm.models.length !== vm.list.length) || (vm.list && vm.list.length < 1)) {
                vm.showModel = true;
                vm.list = vm.models;
            }

            function sortFunc(item) {
                return _.chain(item.title).deburr().toLower().value();
            }

        }

        function reloadExams(template) {
            examService.getExamListTable({
                patient: vm.currentPatient,
                limit: false,
                page: false,
                order: "-exam_date",
                search: {
                    templates: [template.id]
                }
            }).then(data => {
                initExams = data.list;
            });
        }

        function useItem(item) {
            if (_.has(item, "exam_date")) {
                editItem(item)
            } else {
                useModel(item)
            }
        }

        function useModel(model) {
            examService.newState(model.template, model);
        }

        function addModel(model) {
            if (vm.isForm) examService.contentSubject.next(model);
            else useModel(model);
        }

        function editItem(item) {
            examService.editState(item, currentTemplate, true);
        }

        function duplicateItem(item) {
            examService.duplicateState(item, currentTemplate);
        }

        function deleteItem(item) {
            examService.removeExam(item, vm.currentPatient).then(_.noop, _.noop);
        }

        function toggleModels() {
            vm.showBiologyExamTree = false;
            vm.showModel = !vm.showModel;

            if (vm.showModel) vm.list = vm.models;
            else vm.list = initExams;
        }
    }

})();
