/**
 * Created by amine on 02/05/2018.
 */
(function () {
    'use strict';

    module.exports = ExamListViewCtrl;

    ExamListViewCtrl.$inject = ["$q", "$scope", "examService", "$transition$", "$translate"];

    function ExamListViewCtrl($q, $scope, examService, $transition$, $translate) {
        let vm = this;

        vm.$onInit = init;
        vm.onReorder = onReorder;
        vm.onPaginate = onPaginate;
        vm.viewExam = viewExam;
        vm.getData = _.mnDelay(getData, 400);
        vm.editExam = editExam;
        vm.duplicateExam = duplicateExam;
        vm.removeExam = removeExam;

        function init() {
            examService.sideNaveSubject.next(false);
            examService.selectedTemplate.next(false);

            vm.currentPatient = _.get($transition$.params('to'), 'pId', null);
            vm.visitContext = !_.isNil(vm.currentPatient);

            vm.paginationLabel = {
                page: $translate['instant']('page'),
                rowsPerPage: $translate['instant']('rowsPerPage'),
                of: $translate['instant']('of')
            }

            vm.query = {
                patient: vm.currentPatient,
                limit: 10,
                page: 1,
                order: "exam_date"
            }

            vm.options = [5, 10, 20, 100];
            vm.total = 0;
            vm.examList = [];

            examService.getExamTemplates().then(success)

            function success(templates) {
                vm.templates = templates;
                getData();
            }
        }

        function getData() {
            vm.promise = examService.getExamListTable(vm.query)
                .then(success)

            function success(data) {
                vm.total = data.length;
                vm.examList = data.list;
            }
        }

        function onReorder(order) {
            vm.query = _.assign(vm.query, {order: order});
            getData();
        }

        function onPaginate(page, limit) {
            vm.query = _.assign(vm.query, {page: page, limit: limit});
            getData();
        }

        function viewExam(exam) {
            if (vm.visitContext) examService.viewState(exam);
            else examService.goTo(exam, "view")
        }

        function editExam(exam) {
            if (vm.visitContext) examService.editExam(exam);
            else examService.goTo(exam, "form")
        }

        function duplicateExam(exam) {
            if (vm.visitContext) examService.duplicateExam(exam);
            else examService.goTo(exam, "duplicate")
        }

        function removeExam(exam) {
            examService.removeExam(exam, vm.currentPatient)
                .then(getData);
        }
    }

})();
