(function () {

    'use strict';

    const COLOR_PICKER_PARAMS = require('../../parameters/json/color-picker-config.json');

    class ProcedureGroupDialogCtrl {
        constructor(medicalCareService, $mdDialog, $translate) {
            this.medicalCareService = medicalCareService;
            this.dialog = $mdDialog;

            this.options = _.assign({}, COLOR_PICKER_PARAMS, {
                label: $translate.instant('medical_care.color'),
                okText: $translate.instant('select'),
                cancelText: $translate.instant('cancel')
            });
        }

        static get $inject() {
            return ["medicalCareService", "$mdDialog", "$translate"];
        }

        $onInit() {

        }

        submit() {
            this.medicalCareService.saveProcedureGroup(this.model)
                .then(data => this.dialog.hide(data), _.noop);
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: ProcedureGroupDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("../views/procedure-groups-dialog.tpl.html"),
    };

})();
