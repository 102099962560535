/**
 * Created by BETALOS on 12/05/2017.
 */
(function () {

    'use strict';

    const {APPOINTMENT, DELETE} = require('stand-alone/calendar/utils/consts');

    module.exports = PlanningDetailsCtrl;

    PlanningDetailsCtrl.$inject = ["frontDeskService", "$transition$", "mnWebSocket", "$mdDialog"];

    function PlanningDetailsCtrl(frontDeskService, $transition$, mnWebSocket, $mdDialog) {
        let vm = this;

        let isVisit = _.startsWith($transition$.to()['name'], "app.visit");

        let currentPatient = _.get($transition$.params('to'), isVisit ? 'pId' : 'patient');
        let currentPlanning = _.get($transition$.params('to'), 'planning');

        vm.$onInit = init;

        vm.editPlanning = editPlanning;
        vm.duplicatePlanning = duplicatePlanning;

        vm.addAppointment = addAppointment;
        vm.removeAppointment = removeAppointment;
        vm.editAppointment = editAppointment;

        function init() {
            frontDeskService.planningEditSubject.next(currentPlanning);

            vm.promise = frontDeskService.getPlanning(currentPlanning)
                .then(success);

            function success(data) {
                vm.planning = data;
            }
        }

        function editPlanning(planning, ev) {
            frontDeskService.editPlanning(planning, ev)
                .then(init);
        }

        function duplicatePlanning(planning, ev) {
            frontDeskService.duplicatePlanning(planning, ev)
                .then(success);

            function success(data) {
                frontDeskService.planningListSubject.next(data);
            }
        }

        function editAppointment(appointment, ev) {
            frontDeskService.editAppointment(appointment, ev)
                .then(init);
        }

        function addAppointment($event) {
            $mdDialog.show(_.assign(require('../dialogs/appointment-dialog'), {
                targetEvent: $event,
                locals: {
                    timeRange: {
                        start: moment(),
                        end: moment().add(20, 'm')
                    },
                    hasPause: false,
                    patient: currentPatient,
                    event: null
                }
            })).then(success);

            function success(appointment) {
                vm.promise = mnWebSocket.call("frontdesk.Planning.update_planning_appointments", {
                    planning: currentPlanning,
                    appointment: appointment.id
                }).then(init);
            }
        }

        function removeAppointment(appointment) {
            vm.tablePromise = frontDeskService.removeAppointment(appointment.id)
                .then(doneCallbacks);

            function doneCallbacks() {
                mnWebSocket.pub("frontdesk.Calendar.notify", {cmd: DELETE, type: APPOINTMENT, event: appointment.id}, false)
                    .then(init);
            }
        }

    }

})();
