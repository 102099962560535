/**
 * Created by amine on 07/05/2018.
 */
(function () {

    'use strict';

    module.exports = ExamModelFormCtrl;

    ExamModelFormCtrl.$inject = ["$scope", "examService", "$state", "$transition$", "visitService", "system", "$q"];

    function ExamModelFormCtrl($scope, examService, $state, $transition$, visitService, system, $q) {
        let vm = this;
        // let timeFormat = system['time_format'].js
        let modelsSubscription = null;

        vm.$onInit = init;
        vm.$onDestroy = onDestroy;
        vm.saveModel = saveModel;
        vm.deleteModel = examService.deleteExamModel;
        vm.cancel = examService.listState;

        function init() {
            vm.modelId = _.get($transition$.params('to'), 'modelId', null);
            vm.copy = _.get($transition$.params('to'), 'copy', null);

            vm.editorName = _.uniqueId('exam-editor-');
            vm.editorConfigKey = "exam_editor";
            vm.editorOpts = {}

            examService.sideNaveSubject.next(true);
            examService.selectedTemplate.next(vm.template);


            if (_.isNil(vm.modelId)) {
                examService.getExamModel(vm.copy).then(duplicate, error);
            } else {
                examService.getExamModel(vm.modelId).then(edit, error);
                modelsSubscription = examService.examModelSubject.subscribe(modelsChanged);

                function modelsChanged(status) {
                    if (_.has(status, "deleted")) {
                        examService.listState();
                    }
                }
            }

            function edit(data) {
                vm.model = data;
                examService.currentModelSubject.next({id: vm.model.id, type: "edit", model: true});
                examService.selectedTemplate.next(vm.model.template);
            }

            function duplicate(data) {
                vm.model = {
                    title: data.title + " (COPY)",
                    content: data.content,
                    template: data.template
                }
                examService.currentModelSubject.next({id: data.id, type: 'duplicate', model: true});
                examService.selectedTemplate.next(vm.model.template);
            }

            function error() {
                examService.listState();
            }
        }

        function saveModel(quit) {
            return examService.saveExamModel(vm.model)
                .then(success);

            function success(data) {
                if (_.isNil(vm.model.id)) {
                    vm.model.id = data.id;
                    examService.currentModelSubject.next({id: data.id, type: 'edit', model: true});

                    if (!quit) examService.editModelState(data);
                }

                if (quit) examService.listState();
            }
        }

        function onDestroy() {
            if (!!modelsSubscription) modelsSubscription.unsubscribe();
        }
    }

})();
