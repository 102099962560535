/**
 * Created by Amine on 27/07/2016.
 */
(function () {
    'use strict';

    const angular = require('angular');

    const mnExamLinks = require("./components/exam-links");

    const ExamContainerCtrl = require("./controllers/ExamContainerCtrl");
    const ExamListViewCtrl = require("./controllers/ExamListViewCtrl");
    const ExamViewCtrl = require("./controllers/ExamViewCtrl");
    const ExamFormCtrl = require("./controllers/ExamFormCtrl");
    const ExamModelFormCtrl = require("./controllers/ExamModelFormCtrl");
    const ReportsMainCtrl = require("./controllers/ReportsMainCtrl");

    const examService = require("./services/examService");

    angular
        .module("medinet")

        .component("mnExamLinks", mnExamLinks)

        .controller('ExamContainerCtrl', ExamContainerCtrl)
        .controller('ExamListViewCtrl', ExamListViewCtrl)
        .controller('ExamViewCtrl', ExamViewCtrl)
        .controller('ExamFormCtrl', ExamFormCtrl)
        .controller('ExamModelFormCtrl', ExamModelFormCtrl)
        .controller('ReportsMainCtrl', ReportsMainCtrl)

        .service("examService", examService);

})();