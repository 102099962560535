(function () {

    'use strict';


    class FormTemplateCtrl {
        constructor(measureService) {
            this.measureService = measureService;
        }

        static get $inject() {
            return ["measureService"];
        }

        $onInit() {
            this.measurements = [];
            this.patientId = this.patientId || null;
            setTimeout(() => {
                this.formTemplate = this.formTemplate || {};
                this.measureService.getMeasurementResume({patient_id: this.patientId}).then(data => {
                    this.measurements = _.get(data, 'measurements');
                })
            }, 100);
        }

        checkChoice(f, o) {
            return _.includes(f.value, o);
        }

        addChoice(f, o, idx) {
            if (_.includes(f.value, o)) {
                f.value.splice(idx, 1)
            } else {
                f.value = f.value || [];
                f.value.push(o);
            }
        }

        loadMeasurementValue(f) {
            f.value = _.get(this.measurements, `${f.options.measurement.id}.value`);
        }

        calculateFormula(fd) {
            let s = fd.options.formula
            _.forEach(this.formTemplate.blocks, (b) => {
                return _.forEach(b.fields, (f) => {
                    s = _.replace(s, `{${b.name}.${f.name}}`, f.value)
                })
            });
            try {
                fd.value = eval(s);
            } catch (error) {
                return fd.value = null;
            }
        }
    }


    module.exports = {
        template: require("../views/form-template.tpl.html"),
        bindings: {
            "formTemplate": "=template",
            "patientId": "<patient"
        },
        controllerAs: "vm",
        controller: FormTemplateCtrl
    }
})();