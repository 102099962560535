/**
 * Created by BETALOS on 16/01/2017.
 */
(function () {

    'use strict';

    module.exports = {
        controller: mnExamLinksCtrl,
        controllerAs: "vm",
        bindings: {
            lock: "=?",
            change: "<?templateChange"
        },
        template: require("exam/views/exam-links.tpl.html")
    };

    mnExamLinksCtrl.$inject = ["examService", "configService", "$q", "$state", "$stateParams", "$element"];

    function mnExamLinksCtrl(examService, configService, $q, $state, $stateParams, $element) {
        let vm = this;
        let config = {
            disabled: {},
            favorite: {},
            order: {}
        };

        vm.$onInit = init;
        vm.selectTemplate = selectTemplate;
        vm.lockChange = lockChange;

        function init() {
            let selectedTemplateSubscription;
            vm.hasLock = $element.is('[lock]');

            vm.change = _.isNil(vm.change) ? examService.newState : vm.change

            if ($element.is(".consultation-exam-cuts")) {
                $(".md-menu", $element.is(".consultation-exam-cuts")).addClass("layout-row");
            }

            $q.all([examService.getExamTemplates(), configService.get("exam_config", true)])
                .then(success);

            function success(data) {
                config = _.assign(config, data[1]);
                let templates = _.reduce(data[0], iter, {
                    fav: [],
                    nofav: []
                });

                vm.templates = _.sortBy(templates.nofav, "order");
                vm.favoritesTemplates = _.sortBy(templates.fav, "order");

                selectedTemplateSubscription = examService.selectedTemplate.subscribe(templateSelected);

                vm.$onDestroy = onDestroy;
            }

            function onDestroy() {
                selectedTemplateSubscription.unsubscribe();
            }

            function templateSelected(template) {
                if (_.isNumber(template)) {
                    examService.selectedTemplate.next(
                        _.find(_.concat(vm.templates, vm.favoritesTemplates), {id: template})
                    )
                } else if (!_.isNil(template) && template) {
                    vm.selectedTemplate = template;
                    vm.activeMenu = !_.isNil(_.find(vm.templates, template));
                } else {
                    vm.selectedTemplate = null;
                    vm.activeMenu = false;
                }
            }

            function iter(templates, item) {
                let disabled = _.get(config.disabled, item.id, false)
                let favorite = _.get(config.favorite, item.id, false)
                let order = _.get(config.order, item.id, 1000)

                item.order = order;

                if (!disabled) {
                    if (favorite) templates.fav.push(item)
                    else templates.nofav.push(item)
                }

                return templates;
            }
        }

        function selectTemplate(template) {
            examService.selectedTemplate.next(template);
            vm.change(template)
        }

        function lockChange() {
            vm.lock = !vm.lock;
        }
    }

})();