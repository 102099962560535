/**
 * Created by amine on 30/04/2018.
 */
(function () {

    'use strict';

    module.exports = examService;

    const {Subject, BehaviorSubject} = require("rxjs");
    const EXPORT_DIALOG = require('../dialogs/export-exam-model-dialog');

    examService.$inject = ["$q", "$http", "$state", "visitService", "mnWebSocket", "$mdDialog", "textEditorService", "$translate"];

    function examService($q, $http, $state, visitService, mnWebSocket, $mdDialog, textEditorService, $translate) {
        let self = this;

        self.selectedTemplate = new BehaviorSubject(false);
        self.formSubject = new BehaviorSubject(false);
        self.examModelSubject = new Subject();
        self.currentModelSubject = new Subject();
        self.sideNaveSubject = new Subject();
        self.contentSubject = new Subject();

        self.listState = listState;
        self.viewState = viewState;
        self.editState = editState;
        self.newState = newState;
        self.duplicateState = duplicateState;
        self.templateListState = templateListState;
        self.formModelState = formModelState;
        self.getExam = getExam;
        self.prePrint = prePrint;
        self.handleSaving = handleSaving;
        self.simpleSaving = simpleSaving;
        self.getExamTemplates = getExamTemplates;
        self.getExamTemplate = getExamTemplate;
        self.saveExamTemplate = saveExamTemplate;
        self.getExamModel = getExamModel;
        self.getExamModels = getExamModels;
        self.getExamListTable = getExamListTable;
        self.editExam = editExam;
        self.duplicateExam = duplicateExam;
        self.removeExam = removeExam;
        self.editModelState = editModelState;
        self.duplicateModelState = duplicateModelState;
        self.saveExamModel = saveExamModel;
        self.addExamModel = addExamModel;
        self.moveExamModel = moveExamModel;
        self.deleteExamModel = deleteExamModel;
        self.getVisitExamList = getVisitExamList;
        self.globalListState = globalListState;
        self.goTo = goTo;


        function listState() {
            self.currentModelSubject.next({id: null, type: null});
            $state.go("app.visit.exam.list-view", {}, {location: 'replace'});
        }

        function viewState(exam, reload) {
            let promise = $state.go('app.visit.exam.view', {examId: exam.id}, {inherit: true})
            if (reload) promise.then($state.reload);
        }

        function formState(exam, template, model, duplicate, reload, supQuery = {}) {
            let params = {
                template: template.id,
                examId: null,
                model: null,
                copy: null
            };

            if (duplicate) params['copy'] = _.get(exam, "id", null);
            else if (!_.isNil(exam)) params['examId'] = _.get(exam, "id", null);
            else if (!_.isNil(model)) params['model'] = _.get(model, "id", null);
            let promise = $state.go('app.visit.exam.form', _.assign(params, supQuery), {inherit: true});

            if (reload) promise.then($state.reload);
        }

        function duplicateState(exam, template) {
            formState(exam, template, null, true, true);
        }

        function newState(template, model) {
            formState(null, template, model, false, true);
        }

        function editState(exam, template, reload, supQuery = {}) {
            formState(exam, template, null, false, reload, supQuery = {});
        }

        function templateListState() {
            self.currentModelSubject.next({id: null, type: null});
            $state.go('app.parameters.exam-setup', {}, {inherit: true});
        }

        function globalListState() {
            $state.go('app.reports.list', {}, {inherit: true, location: 'replace'});
        }

        function formModelState(template, model, duplicate, reload) {
            let params = {
                template: null,
                modelId: null,
                copy: null
            }

            if (duplicate) params.copy = _.get(model, "id", null);
            else if (!_.isNil(model)) params.modelId = _.get(model, "id", null);
            else params.template = _.get(template, "id", template);

            let promise = $state.go('app.parameters.exam-setup.form', params, {inherit: true});

            if (reload) promise.then($state.reload);
        }

        function getExam(pk) {
            let deferred = $q.defer();
            let url = `/api/exam-form/${pk}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function simpleSaving(exam, patientId) {
            let deferred = $q.defer();
            const url = '/api/exam-form/';

            $http.post(`${url}${_.has(exam, 'id') ? `${exam.id}/` : ''}?patient=${patientId}`, exam)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function handleSaving(exam, patientId, visitId) {
            let deferred = $q.defer();
            let url = '/api/exam-form/';

            let template = self.selectedTemplate.getValue();

            if (_.has(exam, 'id')) {
                $http.put(`${url}${exam.id}/?patient=${patientId}`, exam).then(success, deferred.reject);
            } else {
                let procedures = template['procedure_id'] ? [template['procedure_id']] : [];

                mnWebSocket.call('visit.VisitPayment.set_visit_procedures', {visit: visitId, procedures: procedures})
                    .then(startSave);
            }

            function startSave(procedures_uid) {
                if (_.size(procedures_uid) === 1) _.assign(exam, {
                    procedure_uid: _.head(procedures_uid)
                });

                $http.post(`${url}?patient=${patientId}`, exam).then(success, deferred.reject);
            }

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function prePrint(save, editorName) {
            return function () {
                let deferred = $q.defer();

                textEditorService
                    .getEditor(editorName)
                    .compileContent()
                    .then(() => {
                        save().then(() => {
                            deferred.resolve(false);
                        });
                    });

                return deferred.promise;
            }
        }

        function getExamTemplates() {
            let deferred = $q.defer();
            let url = "/api/exam-template-list/";

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getExamTemplate(template) {
            let deferred = $q.defer();
            let url = `/api/exam-template-list/${template.id ? template.id : template}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function saveExamTemplate(template) {
            let deferred = $q.defer();
            let url = `/api/exam-template-list/${template.id ? `${template.id}/` : ''}`;

            $http.post(url, template)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getExamModel(model) {
            let deferred = $q.defer();
            let url = `/api/exam-editor-model/${model}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getExamModels() {
            let deferred = $q.defer();
            let url = "/api/exam-editor-model/"

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {

                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getExamListTable(query) {
            return mnWebSocket.call("exam.ExamList.list", query);
        }

        function editExam(exam) {
            self.selectedTemplate.next(exam.template);
            editState(exam, exam.template);
        }

        function duplicateExam(exam) {
            self.selectedTemplate.next(exam.template);
            duplicateState(exam, exam.template);
        }

        function removeExam(exam, patient, ev) {
            let deferred = $q.defer();
            let url = `/api/exam-form/${exam.id}/?patient=${patient}`;

            let data = {
                visit: exam.visit_id,
                procedures_uid: _.isNil(exam.procedure_uid) ? [] : [exam.procedure_uid]
            }

            let confirm = $mdDialog.confirm()
                .title($translate['instant']('exam_remove_confirm'))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'));

            $mdDialog.show(confirm).then(remove, deferred.reject);

            function remove() {
                $q.all([$http.delete(url), mnWebSocket.call('visit.VisitPayment.remove_visit_procedures', data)])
                    .then(success, deferred.reject);
            }

            function success() {
                if (!_.eq($state.current['name'], "app.visit.exam.list-view")) listState();

                deferred.resolve(true);
            }

            return deferred.promise;
        }

        function editModelState(model) {
            $state.go("app.visit.exam.model-form", {modelId: model.id, copy: null}).then($state.reload);
        }

        function duplicateModelState(model) {
            $state.go("app.visit.exam.model-form", {modelId: null, copy: model.id}).then($state.reload)
        }

        function saveExamModel(model) {
            let deferred = $q.defer();
            let url = `/api/exam-editor-model/${model.id ? model.id + '/' : ''}`;

            $http.post(url, model)
                .then(success, deferred.reject);

            function success(response) {
                self.examModelSubject.next({"added": response.data});
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function addExamModel(exam, ev) {
            const deferred = $q.defer();
            const confirm = $mdDialog.mnPromptDialog()
                .title('exam_model_title')
                .event('exam.ExamModel.title_validation')
                .placeholder('title')
                .targetEvent(ev)

            $mdDialog
                .show(confirm)
                .then(
                    title => {
                        const content = textEditorService
                            .getModelContent(exam.content);

                        const model = {
                            content: content,
                            template: _.isNumber(exam.template) ? {id: exam.template} : _.pick(exam.template, ["id"]),
                            title: title
                        }

                        return saveExamModel(model).then(data => {
                            deferred.resolve(data);
                        }, deferred.reject);
                    }
                );

            return deferred.promise;
        }

        function moveExamModel(model, ev) {
            $mdDialog.show(_.assign({}, EXPORT_DIALOG, {
                targetEvent: ev,
                locals: {
                    model: _.cloneDeep(model)
                }
            }))
        }


        function deleteExamModel(model, ev) {
            let confirm = $mdDialog.confirm()
                .title($translate['instant']('exam_model_remove_confirm', _.pick(model, 'title')))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'));

            $mdDialog.show(confirm).then(done);

            let deferred = $q.defer();

            function done() {
                let url = `/api/exam-editor-model/${model.id}/`;

                $http.delete(url)
                    .then(success, deferred.reject);
            }

            function success() {
                self.examModelSubject.next({"deleted": model.id});
                deferred.resolve(true);
            }

            return deferred.promise;
        }

        function getVisitExamList(visit) {
            return mnWebSocket.call('exam.ExamList.full_list', {visit: _.isObject(visit) ? visit.id : visit})
        }

        function goTo(exam, state) {
            let params = {
                nv: 1,
                pId: exam.patient_id,
                visitId: exam.visit_id
            }
            switch (state) {
                case "view":
                case "form":
                    params['examId'] = exam.id;
                    params['template'] = state == "form" ? exam.template.id : null;
                    state = 'app.visit.exam.' + state
                    break
                case "duplicate":
                    params['copy'] = exam.id;
                    params['template'] = exam.template.id;
                    state = 'app.visit.exam.form';
                    break;
                default:
                    state = 'app.visit.exam';
            }

            $state.go(state, params, {inherit: true})
        }
    }
})();
